import React, { useState } from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import SeriesTableGrid from 'components/category/series-table-grid'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import { getSrc } from 'gatsby-plugin-image'
import getSeriesSpecsTables from 'utils/get-series-specs-tables'

const ManitouSeries = props => {
  const {
    data: {
      allSanityManitouEquipment,
      sanityManitouEquipmentCategory,
      sanityManitouEquipmentSubcategory,
      sanityManitouEquipmentSeries,
    },
  } = props

  const [{ breadcrumbs, meta, tables }] = useState(() => {
    const breadcrumbs = [
      {
        name: 'Home',
        schemaName: 'Hutson Inc',
        link: '/',
      },
      {
        name: 'Manitou',
        link: '/manitou/',
      },
      {
        name: sanityManitouEquipmentCategory.title,
        link: `/manitou/${sanityManitouEquipmentCategory.handle}/`,
      },
      {
        name: sanityManitouEquipmentSubcategory.title,
        link: `/manitou/${sanityManitouEquipmentCategory.handle}/${sanityManitouEquipmentSubcategory.handle}/`,
      },
      {
        name: sanityManitouEquipmentSeries.title,
        link: `/manitou/${sanityManitouEquipmentCategory.handle}/${sanityManitouEquipmentSubcategory.handle}/${sanityManitouEquipmentSeries.handle}/`,
      },
    ]

    const tables = getSeriesSpecsTables(
      allSanityManitouEquipment.nodes.map(node => {
        return {
          name: node.title,
          slug: node.slug,
          image: getSrc(node.firstImage.asset.gatsbyImageData),
          seriesTableData: node.tableSpecs,
        }
      }),
      `Compare Manitou ${sanityManitouEquipmentSeries.title}`
    )

    const meta = {
      title: `Manitou ${sanityManitouEquipmentSeries.title} | Hutson Inc`,
      description: `Check out Manitou ${sanityManitouEquipmentSeries.title} at Hutson.`,
      keywords: [
        sanityManitouEquipmentCategory.title,
        sanityManitouEquipmentSubcategory.title,
        sanityManitouEquipmentSeries.title,
      ].toString(),
      itemList: JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'ItemList',
        'numberOfItems': allSanityManitouEquipment.length,
        'itemListElement': allSanityManitouEquipment.nodes.map((product, index) => ({
          '@type': 'ListItem',
          'position': index + 1,
          'url': `https://www.hutsoninc.com${product.slug}`,
        })),
      }),
    }

    return { breadcrumbs, meta, tables }
  })
  return (
    <Layout>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta name='keywords' content={meta.keywords} />
        <script type='application/ld+json'>{meta.itemList}</script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Hero
        title={`Manitou ${sanityManitouEquipmentSeries.title}`}
        image={sanityManitouEquipmentSeries.heroImageDesktop.asset.gatsbyImageData}
        breadcrumbs={breadcrumbs}
        overlayOpacity={0.15}
      />

      <Content kind='full'>
        <SeriesTableGrid tables={tables} />
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 400px;
`

export const pageQuery = graphql`
  query manitouSubcategoryTemplate($category: String!, $subcategory: String!, $series: String!) {
    allSanityManitouEquipment(
      filter: { series: { handle: { eq: $series } }, published: { eq: true } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        firstImage {
          asset {
            gatsbyImageData(width: 640)
          }
        }
        slug
        tableSpecs {
          data
          property
        }
        title
      }
    }
    sanityManitouEquipmentCategory(handle: { eq: $category }) {
      handle
      title
    }
    sanityManitouEquipmentSubcategory(handle: { eq: $subcategory }) {
      handle
      title
    }
    sanityManitouEquipmentSeries(handle: { eq: $series }) {
      handle
      heroImageDesktop {
        asset {
          gatsbyImageData(width: 1366)
        }
      }
      title
    }
  }
`

export default ManitouSeries
